"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTimedToggle = void 0;
var react_1 = require("react");
function useTimedToggle(initialValue) {
    var _a = react_1.useState(false), value = _a[0], setValue = _a[1];
    var timeoutRef = react_1.useRef();
    var initialValueRef = react_1.useRef(initialValue);
    var toggleValue = function (timeout) {
        clearTimeout(timeoutRef.current);
        setValue(!initialValueRef.current);
        timeoutRef.current = window.setTimeout(function () { return setValue(initialValueRef.current); }, timeout);
    };
    react_1.useEffect(function () { return function () { return clearTimeout(timeoutRef.current); }; }, []);
    return [value, toggleValue];
}
exports.useTimedToggle = useTimedToggle;
